





















































































































































































import ImageUpload from "@/components/admin/ImageUpload.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import MailsService from "@/services/MailsService";

const configNamespace = namespace("Config");

@Component({
  components: { ImageUpload },
})
export default class BasicInfo extends Vue {
  $refs!: Vue["$refs"] & {
    image: any;
  };

  mailboxNotValidated = false;
  isLoading = false;
  submitted = false;

  @configNamespace.Getter("all")
  config!: any;

  @configNamespace.Action("fetchAll")
  fetchAll!: () => Promise<void>;

  @configNamespace.Action("update")
  update!: (config: any) => Promise<void>;

  async doSave() {
    this.submitted = true;
    this.$validator.validateAll().then(async (isValid) => {
      if (!isValid) {
        return;
      }
      this.isLoading = true;

      this.$refs.image.uploadImage();
      await this.update(this.config);
      this.$root.$bvToast.toast("Basic info saved!", {
        variant: "success",
      });
      this.doFetchAll();
      this.submitted = false;
      this.isLoading = false;
    });
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll();
    this.mailboxNotValidated = !(await MailsService.validateMailbox(
      this.config.fromAddress
    ));
    this.isLoading = false;
  }

  updateFavicon(newUrl) {
    this.config.favicon = newUrl;
  }

  created() {
    this.doFetchAll();
  }
}
